import React, { useState, useEffect, useContext, useRef } from "react";

import Container from "../../components/container";
import LazyImage from "../../components/LazyImage";
import Cases from "./Cases";
import Breadcrumb from "../../components/BreadCrumb";
import FullLoader from "../../components/Loader/FullLoader";
import StudyMaterialCard from "../../components/StudyMaterialCard";
import { saveAs } from "file-saver";
import ReportModal from '../../components/Modal/ReportModal';

import Essays from "../../assets/images/main-page-materials/essays.png";
import Notes from "../../assets/images/main-page-materials/notes.png";
import Platform from "../../assets/images/main-page-materials/case-summaries.png";
import Quiz from "../../assets/images/main-page-materials/quiz.png";
import Tips from "../../assets/images/main-page-materials/fashcard.png";
import Tutorials from "../../assets/images/main-page-materials/tutorials.png";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getApiKey, getUser, slugToText } from "../../helpers";
import apiRequest from "../../helpers/apiRequest";
import Button from "../../components/EmptyButton";
import { connect, useDispatch, useSelector } from "react-redux";
import { checkListAction, createErrorAlert, getCatalogue } from "../../redux/actions";
import { catalogueData, checkListData } from "../../redux/selectors";
import { GoCopy } from "react-icons/go";
import Context from "../../services/Context";
import { postMailChimpAction } from "../../redux/actions/postMailChimpAction";
import noApiRequests from "../../helpers/noApiRequests";

const RegisteredUsers = ({ match, checkListData, postMailChimp }) => {
  const param = useParams();
  const locations = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const previousLengthRef = useRef(0);

  const [state, setState] = useState({
    studyModule: null,
    firstTimeCase: null,
    firstTimeTitle: null,
    ogDescription: null,
    ogUrl: null,
    description: null,
    canonical: null,
    keywords: null,
    robots: false,
    paidModules: [],
    dataCatalogue: [],
    user: JSON.parse(getUser()),
    // loadingState: true,
    resObj: {},
  });
  const [loadingState, setLoadingState] = useState(true);
  const [checkListDisplay, setCheckListDisplay] = useState(false);
  const [studyModuleState, setStudyModuleState] = useState( state.studyModule !== null
    ? slugToText(state.studyModule)
    : state.studyModule)
  const [emailError, setEmailError] = useState(false);
  const [emailCheckList, setEmailCheckList] = useState("");
  const [open, setOpen] = useState(false)





  const context = useContext(Context);

  const userCurrency =
    context?.value?.userCurrency || state.user?.user_currency;

  const breadCrumbItem = [
    { title: "Get study modules", link: "/" },
    {
      title:
        state.studyModule !== null
          ? slugToText(state.studyModule)
          : state.studyModule,
      link: `/study-module/${state.studyModule}`,
    },
    { title: "Case Summaries", link: `/case-studies/${state.studyModule}` },
  ];

  useEffect(() => {
    // const currentLength = context?.value?.allModules?.length || 0;
    // if (currentLength > previousLengthRef.current) {
      if(!noApiRequests().includes(window.location.pathname)){
        siteSearch();
      }
    // }
    // previousLengthRef.current = currentLength;
  }, []);

  useEffect(() => {
    localStorage.setItem("urlSlug", param.slug);
    window.scrollTo({ top: 0, left: 0 });
  });

  useEffect(() => {
    for (let i = 0; i < context?.value?.dataCatalogue?.length; i++) {
      if (context?.value?.dataCatalogue[i]?.is_paid === true) {
        if (
          !state.paidModules.includes(
            context?.value?.dataCatalogue[i]?.study_module?.slug
          )
        ) {
          state.paidModules.push(
            context?.value?.dataCatalogue[i]?.study_module?.slug
          );
        }
      }
    }
  }, [context?.value?.dataCatalogue]);

  useEffect(() => {
    let isModuleInList
    if (Array.isArray(checkListData)) {
      isModuleInList = checkListData?.some(
        (item) => item.title?.toLowerCase().includes(studyModuleState?.toLowerCase())
      );
    }
    if (isModuleInList) {
      setCheckListDisplay(isModuleInList);
    } else if (
      studyModuleState === "Principles of Operations Management" || studyModuleState === "Principles Of Operations Management" ||
      studyModuleState === "Organisational Behaviour" ||
      studyModuleState === "Business Economics" ||
      studyModuleState === "Business Tax" ||
      studyModuleState === "Internation financial accounting" ||
      studyModuleState === "Applied Management Accounting" ||
      studyModuleState === "International Financial Reporting" ||
      studyModuleState === "Financial Management" ||
      studyModuleState === "Macroeconomics" ||
      studyModuleState === "Marketing" ||
      studyModuleState === "Business"
    ) {
      setCheckListDisplay(true);
    } else {
      setCheckListDisplay(true);
    }
  }, [checkListData, studyModuleState]);

  const siteSearch = async () => {
    console.log('in site search')
    await apiRequest({
      method: "get",
      url: `content/case/search/?case=${param.slug}`,
    }).then((res) => {
      for (let j = 0; j < res.data.length; j++) {
        if (param.slug === res.data[j].slug) {
          setState((prev) => ({
            ...prev,
            resObj: res.data[j],
          }));
        }
      }
      // for (let i = 0; i < context?.value?.allModules.length; i++) {
        // if (
        //   parseInt(res?.data[0]?.study_module.id) ===
        //   parseInt(context?.value?.allModules[i].id)
        // ) {
        //   localStorage.setItem(
        //     "studyModule",
        //     context?.value?.allModules[i].slug
        //   );
          setStudyModuleState(res.data[0].title);
          setState((prev) => ({
            ...prev,
            studyModule: res.data[0]?.study_module?.slug,
            firstTimeCase: res.data[0]?.content,
            firstTimeTitle: res.data[0]?.title,
            description: res?.data[0]?.seo_fields?.seo_description,
            ogDescription: res?.data[0]?.seo_fields?.og_description,
            ogUrl: res?.data[0]?.seo_fields?.og_url,
            canonical: res?.data[0]?.seo_fields?.seo_canonical_data,
            keyword: res?.data[0]?.seo_fields?.keywords,
            robots:
              res?.data[0]?.seo_fields?.robots === true
                ? "index, follow"
                : "noindex, nofollow",
          }));
          setLoadingState(false);
        // }
      // }
    });
  };

  const notes = () => {
    history.push({
      pathname: `/${state.studyModule}/notes`,
      state: { name: state.studyModule },
    });
  };

  const moduleCheck = () => {
    if (
      studyModuleState === "International Criminal Law" ||
      studyModuleState === "Media Law" ||
      studyModuleState === "Aviation Law" ||
      studyModuleState === "Criminology" ||
      studyModuleState === "Money Laundering" ||
      studyModuleState === "International Banking Law"
    ) {
      return "firstCheck";
    } else if (
      studyModuleState === "Principles Of Operations Management" || studyModuleState === "Principles of Operations Management" ||
      studyModuleState === "Organisational Behaviour" ||
      studyModuleState === "Business Economics" ||
      studyModuleState === "Business Tax" ||
      studyModuleState === "Internation financial accounting" ||
      studyModuleState === "Applied Management Accounting" ||
      studyModuleState === "International Financial Reporting" ||
      studyModuleState === "Financial Management" ||
      studyModuleState === "Macroeconomics" ||
      studyModuleState === "Marketing" ||
      studyModuleState === "Business"
    ) {
      return "secondCheck";
    }
  };

  const handleDownloadCheckList = () => {
    let conditionSatisfied = false;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailCheckList.length === 0) {
      setEmailError(true);
      return;
    }
    if (emailRegex.test(emailCheckList)) {
      for (let i = 0; i < checkListData.length; i++) {
        if (checkListData[i].title.toLowerCase().includes(studyModuleState.toLowerCase())) {
          if (!conditionSatisfied) {
            conditionSatisfied = true;

            window.open(checkListData[i].pdf, "_blank")
            saveAs(
              checkListData[i].pdf,
              `${studyModuleState.toLowerCase().replace(" ", "-")}.pdf`
            );
          }
        }
      }
      postMailChimp(emailCheckList, studyModuleState).then((res) => {
        dispatch(createErrorAlert(200, '', res.data.message, 200))
      })
      setEmailError(false);
      setEmailCheckList("");
      if (moduleCheck() === 'secondCheck') {
        handleSuccess()
      }
    } else {
      setEmailError(true);
    }
  };

  const handleSuccess = () => {
    dispatch(createErrorAlert(400, '', 'You have successfully signed up', 400))
  }

  const cases = () => {
    history.push({
      pathname: `/case-studies/${state.studyModule}`,
      state: { name: state.studyModule },
    });
  };

  const quiz = () => {
    history.push({
      pathname: `/${state.studyModule}/quizzes`,
    });
  };

  const essays = () => {
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT" &&
      state.paidModules.includes(state.studyModule)
    ) {
      history.push({ pathname: `/${state.studyModule}/model-essays` });
      return;
    }
    if (
      getApiKey() &&
      state.user?.subscription_name !== "Basic Subscription" &&
      !state.user?.subscription_name.includes("STARTER") &&
      state.user?.user_type === "STUDENT"
    ) {
      history.push({ pathname: `/${state.studyModule}/model-essays` });
    }
    if (getApiKey() && state.user?.user_type === "TEACHER") {
      history.push({ pathname: `/${state.studyModule}/model-essays` });
    }
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          211,
          "Info",
          "To view Model Essays please Login. No SimpleStudy account? Sign Up",
          211
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === true
    ) {
      history.push({ pathname: `/${state.studyModule}/model-essays` });
    }
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT"
    ) {
      dispatch(
        createErrorAlert(
          203,
          "Info",
          "To view Model Essays you must have a premium subscription",
          203
        )
      );
    }
  };

  const tutorials = () => {
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT" &&
      state.paidModules.includes(state.studyModule)
    ) {
      history.push({
        pathname: `/${state.studyModule}/tutorial-videos`,
        state: { paidModules: state.paidModules },
      });
      return;
    }
    if (
      getApiKey() &&
      state.user?.subscription_name !== "Basic Subscription" &&
      !state.user?.subscription_name.includes("STARTER") &&
      state.user?.user_type === "STUDENT"
    ) {
      history.push({ pathname: `/${state.studyModule}/tutorial-videos` });
    }
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          211,
          "Info",
          "To view Tutorial Videos please Login. No SimpleStudy account? Sign Up",
          211
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT"
    ) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Tutorial Videos you must have a Premium subscription.",
          203
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === true
    ) {
      history.push({ pathname: `/${state.studyModule}/tutorial-videos` });
    }
    if (state.user?.user_type !== "STUDENT") {
      history.push({ pathname: `/${state.studyModule}/tutorial-videos` });
    }
    if (state.user?.subscription_name.includes("STARTER")) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Tutorial Videos you must have a Premium subscription.",
          203
        )
      );
    }
  };
  const flashCards = () => {
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT" &&
      state.paidModules.includes(state.studyModule)
    ) {
      history.push({
        pathname: `/${param.slug}/flashcards`,
        state: { paidModules: state.paidModules },
      });
      return;
    }
    if (
      getApiKey() &&
      state.user?.subscription_name !== "Basic Subscription" &&
      !state.user?.subscription_name.includes("STARTER") &&
      state.user?.user_type === "STUDENT"
    ) {
      history.push({ pathname: `/${state.studyModule}/flashcards` });
    }
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          200,
          "",
          `To view Flash Cards you must be logged in. No SimpleStudy account?`,
          200
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === false
    ) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Flash Cards you must have a Premium subscription.",
          203
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === true
    ) {
      history.push({ pathname: `/${state.studyModule}/flashcards` });
    }
    if (state.user?.user_type !== "STUDENT") {
      history.push({ pathname: `/${state.studyModule}/flashcards` });
    }
    if (state.user?.subscription_name.includes("STARTER")) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Flash Cards you must have a Premium subscription.",
          203
        )
      );
    }
  };

  const handleCopyTitle = (title) => {
    navigator.clipboard.writeText(title);
  };

  const handleJoinClick = () => {
    window.open("https://linktr.ee/SimpleStudying_studygroups", "_blank");
  };

  return (
    <>
      {loadingState ? (
        <FullLoader />
      ) : (
        <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{state.firstTimeTitle}</title>
            <meta name="description" content={state.description} />
            <meta name="title" content={state.firstTimeTitle} />
            <meta name="keywords" content={state.keywords} />
          </Helmet>
          <Container>
            <div className="hidden md:block md:pt-12 flex space-x-4 bd-show">
              <Breadcrumb
                items={breadCrumbItem}
                current={slugToText(param.slug)}
              />
              {/* <div className="w-48 flex mr-0">
                        <Button text={'Report'} className={'mr-0'} onClick={() => {
                            setOpen(!open)
                        }}></Button>
                    </div> */}
            </div>
            <div className="flex flex-col md:flex-row pb-12 md:pb-24 lg:pb-40">
              <div className="hidden custom:flex flex-none custom:w-60 custom:h-96 xl:w-340 xl:h-628 mt-24" >
                <div>
                {checkListDisplay === true && state.user?.subscription_name === "Basic Subscription" && (
                    <div className="w-full  md:block checkListNowSec">
                      <div className="w-full checkListNowSubSec">
                        <h2 className="mb-3.5 text-center">
                          <span>
                            {moduleCheck() === "firstCheck" ? (
                              <div>
                                <strong>
                                  Aiming for a First Class?</strong> <br /><br />
                                <p>We have created a
                                  well-tested method to help students maximise
                                  their chances of getting high grades. Our study technique guide
                                  contains important informationabout how to prepare for your assessments.
                                </p>
                              </div>
                            ) : moduleCheck() === "secondCheck" ? (
                              <div>
                                <strong>
                                  Do you want to improve your grades?</strong><br /><br /> <p>Our study
                                    materials are designed specifically for
                                    maximising your chances of getting a First
                                    Class. Our materials are  created by top
                                    graduates from UK universities.{" "}</p><br />

                                <p>Sign up to get free simple study materials right in your inbox!</p>
                              </div>
                            ) : (
                              <strong>
                                Want to secure high grades in {studyModuleState}?
                              </strong>
                            )}
                          </span>
                        </h2>
                        <p className="mb-3.5 text-center">
                          {moduleCheck() === "firstCheck" ||
                            moduleCheck() === "secondCheck" ? (
                            ""
                          ) : (
                            <span>
                              We’ve created a FREE checklist for you to maximise
                              your chances of getting high grades!
                            </span>
                          )}
                        </p>
                      </div>

                      <form>
                        <div class="relative z-0 w-full mb-6 group">
                          <input
                            type="email"
                            name="emailTwo"
                            style={
                              emailError === true
                                ? { border: "1px solid red" }
                                : { border: "1px solid green" }
                            }
                            className={`emailTwo block py-2.5 px-0 w-full text-sm `}
                            placeholder="Enter your email here "
                            required
                            value={emailCheckList}
                            onChange={(e) => setEmailCheckList(e.target.value)}
                          />
                        </div>

                        <div className="mt-5 mb-7 w-full xl:mt-9 xl:mb-14 mx-auto">
                          <Button
                            type="submit"
                            className="buttonClass"
                            onClick={handleDownloadCheckList}
                          >
                            <p className="font-normal text-15 md:text-small-text xl:text-body-large">
                              {moduleCheck() !== "secondCheck" ? 'Download The Checklist Now' : 'Submit'}
                            </p>
                          </Button>
                        </div>
                      </form>
                    </div>
                  )}
                  {state.user?.subscription_name === "Basic Subscription" ? <><p>Join our law study groups to get free study resources and tips on how to get high grades in your exams and courseworks</p>
                <div className="h-12">
                  <Button className={'mt-10'} filled text={'Join'} onClick={handleJoinClick}></Button>
                </div> 
                </>: null}
                
                </div>
              </div>
              <div className="flex-grow">
                {state.firstTimeTitle && (
                  <div className="flex flex-col items-center">
                    <div className="pt-4 md:pt-10 lg:pt-16">
                      {window.location.pathname === `/${match.params.slug}/` ? (
                        <div className="font-bold text-center">
                          <p className="text-h5 md:text-h3 xl:text-36 flex items-center gap-2 justify-center">
                            {state.firstTimeTitle}
                          </p>
                          <p
                            className="text-primary mt-16 flex items-center gap-2 justify-center"
                            style={{ marginLeft: "35px" }}
                          >
                            <GoCopy
                              className="cursor-pointer"
                              onClick={() => {
                                handleCopyTitle(state.firstTimeTitle);
                              }}
                            />
                            Copy the Full Citation
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="px-2 md:px-8 pt-2 md:pt-6 lg:pt-10">
                  {state.firstTimeCase !== null && (
                    <div>
                      <Cases
                        text={state.firstTimeCase}
                        location={match.params}
                        paidModules={
                          locations?.state?.paidModules || state.paidModules
                        }
                        currentModule={
                          locations?.state?.module || state.studyModule
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="hidden custom:flex flex-none md:w-64 xl:w-340 flex flex-col mt-24">
                {state?.user?.subscription_name !== "Basic Subscription" ? (
                  <div className="h-12">
                    <StudyMaterialCard
                        img={"https://simplestudying.com/static/media/purple-card-image.733abc6c.webp"}
                        imageClass="w-32 h-32 xl:w-36 xl:h-36 pt-6 md:pt-10 lg:pt-14"
                        alt="Purple card image"
                        color="bg-purple"
                        data={''}
                        text={
                          <p className="text-center font-bold text-16 xl:text-h5 xlfont-bold text-white pt-14 xl:pt-10">
                           Sell your Study Materials to Generate Value from your Knowledge
                          </p>
                        }
                        description={
                          <p className="text-small-text xl:text-body-large text-white">
                            <div className="w-60">
                              Publish your notes and essays and get recurring monthly revenues
                            </div>
                          </p>
                        }
                      />
                    {/* <p>Join our law study groups to get free study resources and tips on how to get high grades in your exams and courseworks</p>
                      <Button className={'mt-10'} filled text={'Join'} onClick={handleJoinClick}></Button> */}
                  </div>
                ) : !getApiKey() ||
                  state?.user?.subscription_name === "Basic Subscription" ? (
                  <div className="h-12">
                    <StudyMaterialCard
                      img={
                        "https://simplestudying.com/static/media/purple-card-image.733abc6c.webp"
                      }
                      alt="Purple card image"
                      color="bg-purple"
                      text={
                        <p className="text-center text-17 xl:text-h3 xl:text-bold text-white">
                          Sell your Study Materials <br />
                          to Generate Value from your{" "}
                          <br className="lg:hidden" /> Knowledge
                        </p>
                      }
                      description={
                        <p className="text-13 xl:text-body-large text-white text-center">
                          Publish your notes and essays and{" "}
                          <br className="xl:hidden" /> get{" "}
                          <br className="hidden xl:block" /> recurring monthly
                          revenues!
                        </p>
                      }
                    />
                    <div className="mt-4">
                      <StudyMaterialCard
                        img={
                          "https://simplestudying.com/static/media/green-card-image.5f3b47f3.webp"
                        }
                        alt="Green card image"
                        color="bg-offGreen"
                        text={
                          <p className="text-center text-17 xl:text-h3 xl:text-bold text-white">
                            Get Study Materials and Tutoring
                            <br /> to Improve your
                            <br className="hidden md:block custom:hidden" />{" "}
                            Grades
                          </p>
                        }
                        description={
                          <p className="text-13 xl:text-body-large text-white text-center">
                            Simple study materials and pre-tested <br /> tools
                            helping you to get high grades!
                          </p>
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="pb-20 lg:pb-32">
              <p className="goBackText font-bold text-h3 md:text-36 text-center">
                Go back to:
              </p>
              <div className="pt-6 md:pt-10 lg:pt-16 pb-16 grid grid-cols-2 md:grid-cols-3 gap-y-14 lg:gap-y-16 custom:gap-y-20 justify-items-center custom:px-20">
                <div
                  onClick={notes}
                  className="w-32 h-32 lg:w-56 lg:h-56 custom:w-60 custom:h-60 xl:w-72 xl:h-72 cursor-pointer"
                >
                  <LazyImage src={Notes} className="h-full w-full" />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Notes
                  </p>
                </div>
                <div
                  onClick={cases}
                  className="w-32 h-32  lg:w-56 lg:h-56 custom:w-60 custom:h-60 xl:w-72 xl:h-72 cursor-pointer"
                >
                  <LazyImage src={Platform} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Case Summaries
                  </p>
                </div>
                <div
                  onClick={essays}
                  className="w-32 h-32  lg:w-56 lg:h-56 custom:w-60 custom:h-60 xl:w-72 xl:h-72 cursor-pointer"
                >
                  <LazyImage src={Essays} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Model Essays
                  </p>
                </div>
                <div
                  onClick={quiz}
                  className="w-32 h-32  lg:w-56 lg:h-56 custom:w-60 custom:h-60 xl:w-72 xl:h-72 cursor-pointer"
                >
                  <LazyImage src={Quiz} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Quiz
                  </p>
                </div>
                <div
                  onClick={tutorials}
                  className="w-32 h-32  lg:w-56 lg:h-56 custom:w-60 custom:h-60 xl:w-72 xl:h-72 cursor-pointer"
                >
                  <LazyImage src={Tutorials} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Tutorial Videos
                  </p>
                </div>
                <div
                  onClick={flashCards}
                  className="w-32 h-32  lg:w-56 lg:h-56 custom:w-60 custom:h-60 xl:w-72 xl:h-72 cursor-pointer"
                >
                  <LazyImage src={Tips} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4 mt-6 md:mt-5 lg:mt-9 custom:mt-12">
                    Flashcards
                  </p>
                </div>
              </div>
            </div>
          </Container>
          <ReportModal open={open} setOpen={setOpen} slug={param.slug} content_type={'case study'}/>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  checkListData: checkListData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCheckList: () => dispatch(checkListAction()),
  postMailChimp: (email, study) => dispatch(postMailChimpAction(email, study)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredUsers);